// create the route example.com/topics/topic-slug
// this will dynamicaly create the topic pages
import React, { Fragment } from 'react';
import { graphql } from "gatsby";

import TermsScreen from 'components/screens/TermsScreen/TermsScreen';

// In theory we could pass in props that we'd fetched via Gatsby's GraphQL
export default ({ data }) => (
  <Fragment>
    <TermsScreen data={data}/>
  </Fragment>
);

export const query = graphql`
query GetTerms {
    strapiTerms {
      Content
    }
  }
`;
